(function () {
  import(/* webpackChunkName: "application-code" */ './initiateFunction')
    .then((module) => {
      window.ICMerchantSDK = module.default;
      if (window.onICMerchantSDKReady) {
        window.onICMerchantSDKReady();
      } else {
        console.error(
          `please assign window object onICMerchantSDKReady function for ${window.location.host}`,
        );
      }
    })
    .catch((error) => {
      console.log('File Load error : ', error);
    });
})();
